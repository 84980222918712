<script>
export let title = "";
</script>

<div class="py-5 bg-gradient-dark position-relative" style="margin-bottom: 0 !important">
  <span class="mask bg-gradient-dark opacity-8" />
  <div class="container position-relative z-index-2">
    <div class="row">
      <div class="col-lg-5 col-md-8 m-auto text-left">
        <h5 class="text-white mb-lg-0 mb-5">{title.toUpperCase()}</h5>
      </div>
    </div>
  </div>
</div>
