<header>
  <div
    class="page-header section-height-100"
    style="background-image: url(../static/img/4.jpg);"
  >
    <span class="mask bg-gradient-dark opacity-4"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-sm-9 text-center mx-auto">
          <h1 class="text-white mb-4">¿Quiénes somos?</h1>
          <p class="lead text-white mb-sm-6 mb-4">
            Somos empresa que facilita los procesos de carga al sector
            industrial y comercial en Costa Rica, mediante la venta y alquiler
            de equipo especializado como montacargas y apiladores, ofreciendo
            siempre el mejor servicio, tecnología y calidad.
          </p>
          <p class="text-white h6 text-uppercase mb-4">encuentranos en:</p>

          <div class="d-flex justify-content-center">
            <a href="javascript:;"
              ><i
                class="fab fa-facebook fa-2x text-white me-5"
                aria-hidden="true"
              ></i></a
            >
            <a href="javascript:;"
              ><i
                class="fab fa-instagram fa-2x text-white me-5"
                aria-hidden="true"
              ></i></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="relative"
    style="height: 36px;overflow: hidden;margin-top: -36px; z-index:2"
  >
    <div
      class="w-full absolute bottom-0 start-0 end-0"
      style="transform: scale(2);transform-origin: top center;color: #fbfbfb;"
    >
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>
  </div>
</header>

<section class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6 position-relative">
        <div class="position-relative ms-md-5 me-md-n5">
          <div class="blur-shadow-image">
            <img
              class="image-left rounded-3 img-fluid position-relative top-0 end-0 bg-cover"
              src="../static/img/2.jpg"
            />
          </div>
          <div
            class="colored-shadow"
            style="background-image: url(../static/img/2.jpg&quot;);"
          ></div>
        </div>

        <!-- Second image on the left side of the article -->
        <div
          class="position-absolute bottom-0 mb-10 ms-n2 me-3 start-0 end-2 d-md-block d-none"
        >
          <div class="blur-shadow-image">
            <img
              class="image-container rounded-3 img-fluid position-relative bg-cover"
              src="../static/img/3.jpg"
            />
          </div>
          <div
            class="colored-shadow"
            style="background-image: url(../static/img/3.jpg&quot;);"
          ></div>
        </div>
      </div>
      <div class="col-md-5">
        <!-- First image on the right side, above the article -->
        <div class="position-relative ms-n4 mb-5 mt-8 d-md-block d-none">
          <div class="blur-shadow-image">
            <img
              class="image-right rounded-3 img-fluid position-relative bg-cover"
              src="../static/img/5.jpg"
            />
          </div>
          <div
            class="colored-shadow"
            style="background-image: url(../static/img/5.jpg&quot;);"
          ></div>
        </div>
        <h3 class="mb-4">Nuestra historia.</h3>
        <p>
          Forkilft Equipment S.A. nace en Costa Rica en base a la experiencia y
          conocimiento adquirido por su fundador en los Estados Unidos. Forklift
          Equipment S.A. , ha variado su nombre a LIFT como se le conoce hoy, es
          una empresa cuyo giro comercial principal es alquiler y venta de
          montacargas nuevos y usados complementado con la venta de repuestos y
          llantas para montacargas.
        </p>
        <p>
          Desde su nacimiento en el año 2004, LIFT ha tenido un constante
          desarrollo, ampliando sus áreas de acción a nivel geográfico y de
          servicio. Así, de sus instalaciones originales en Naranjo de Alajuela
          se expande en el 2005 al lado Sur de San José con nuevas instalaciones
          y nuevos servicios como lo es el alquiler de montacargas con servicio
          de conductor. Esta nueva modalidad de servicio le permite a Lift
          alcanzar crecimiento en ventas y participación de mercado además de un
          fortalecimiento de imagen de marca.
        </p>
        <p>
          Hoy, Lift ha alcanzado un excelente posicionamiento de mercado
          contando en los equipos para alquiler y venta con las mejores marcas
          del mercado como Toyota, Komatsu, Caterpillar. Para satisfacer todas
          las necesidades de los clientes cuenta con una excelente variedad de
          equipos con distinta capacidad de levante y diferentes.
        </p>
      </div>
    </div>
    <div class="row mt-6">
      <h3 class="mb-4">Políticas</h3>
      <p>
        En las empresas del Grupo Montacargas desarrollamos un ambiente laboral que promueve el trabajo en equipo, la participación, la consulta, el trato justo y el reconocimiento por el trabajo realizado.
        Buscamos la satisfacción de nuestros clientes, por lo que nos comprometemos a brindar servicios oportunos y eficientes para atender sus necesidades referentes al manejo y movilización de cargas, la venta, alquiler y servicio técnico de montacargas.  
        Propiciamos la renovación constante de nuestros equipos móviles y aditamentos para la prestación del servicio.
      </p>
      <p>
        Trabajamos de manera responsable, aplicando prácticas que permitan eliminar los peligros, reducir los riesgos, prevenir lesiones y enfermedades para mantener un ambiente de trabajo seguro y saludable.  Para esto, participamos activamente en el mantenimiento y actualización del Plan de Preparativos y Respuesta ante Emergencias.
        Apoyamos la mejora continua de la eficacia de nuestro sistema de gestión integrado, para asegurar el cumplimiento de los requisitos pactados con nuestros clientes, los requisitos legales y otros aplicables.
      </p>
    </div>
    <div class="row mt-6">
      <div class="col-lg-4 col-md-8">
        <div class="card card-plain">
          <div class="card-body">
            <p class="mt-4">
              "Brindar un servicio oportuno y de alta calidad, facilitando los
              procesos de carga de nuestros clientes, dentro de un marco de
              armonía constante con el medio ambiente"
            </p>
            <p class="mt-4">MISIÓN</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-8">
        <div class="card card-plain">
          <div class="card-body">
            <p class="mt-4">
              "Ser líderes en el mercado nacional y siendo referencia en los
              distintos sectores industriales y comerciales, por brindar un
              servicio especializado en montacargas, de alta calidad y
              rentabilidad a nuestros clientes."
            </p>
            <p class="mt-4">VISIÓN</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
