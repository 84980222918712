<script>
 import RequestModal from "./RequestModal.svelte";
 let modal;

 import { navigateTo } from "../util";

</script>

<RequestModal bind:this={modal} />

<div class="container position-sticky z-index-sticky top-0">
  <div class="row">
    <div class="col-12">
      <nav
        class="navbar navbar-expand-lg  blur blur-rounded top-0 z-index-fixed shadow position-absolute my-3 py-2 start-0 end-0 mx-4"
      >
        <div class="container-fluid">
          <a
            class="navbar-brand font-weight-bolder ms-sm-3"
            href="#"
            on:click={() => { navigateTo('home') }}
            rel="tooltip"
            title="LIFT Montacargas"
            data-placement="bottom"
            target="_blank"
          >
            <img
              src="../static/img/logo.svg"
              alt=""
              width="50"
              height="24"
              class="d-inline-block align-top"
            />
          </a>
          <button
            type="button"
            class="btn btn-sm  bg-gradient-primary  btn-round mb-0 ms-auto d-lg-none d-block"
            on:click={() => modal.show()}
          >
            Cotizar
          </button>
          <button
            class="navbar-toggler shadow-none ms-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navigation"
            aria-controls="navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon mt-2">
              <span class="navbar-toggler-bar bar1" />
              <span class="navbar-toggler-bar bar2" />
              <span class="navbar-toggler-bar bar3" />
            </span>
          </button>
          <div
            class="collapse navbar-collapse pt-3 pb-2 py-lg-0"
            id="navigation"
          >
            <ul class="navbar-nav navbar-nav-hover mx-auto">
              <li class="nav-item mx-2">
                <a
                  class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                  href="#"
                  on:click={() => { navigateTo('home') }}
                >
                  Inicio
                </a>
              </li>
              <li class="nav-item mx-2">
                <a
                  href="#"
                  class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                  aria-expanded="false"
                  on:click={() => { navigateTo('sale') }}
                >
                  Venta y alquiler
                </a>
              </li>
              <li class="nav-item mx-2">
                <a
                  class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                  href="#"
                  on:click={() => { navigateTo('about') }}
                >
                  Nosotros
                </a>
              </li>
              <li class="nav-item mx-2">
                <a
                  class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                  href="#"
                  on:click={() => { navigateTo('contact') }}
                >
                  Contacto
                </a>
              </li>
            </ul>
            <ul class="navbar-nav d-lg-block d-none">
              <li class="nav-item">
                <button
                  type="button"
                  class="btn btn-sm  bg-gradient-primary  btn-round mb-0 me-1"
                  on:click={() => modal.show()}
                >
                  Cotizar
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <!-- End Navbar -->
    </div>
  </div>
</div>
