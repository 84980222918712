
<script>
  import SaleCard from "./Sale/SaleCard.svelte";
  import SaleHeaderTitle from "./Sale/SaleHeaderTitle.svelte";
</script>

<header>

  <div class="page-header section-height-75" style="background-image: url(../static/img/sale.jpg)">
    <span class="mask bg-gradient-dark"></span>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 mx-auto text-white text-center">
          <h2 class="text-white">Venta y alquiler</h2>
          <p class="lead">Te brindamos equipo especializado para el manejo de su carga</p>
        </div>
      </div>
    </div>
  </div>
  <div class="position-relative overflow-hidden" style="height:36px;margin-top:-35px;">
    <div class="w-full absolute bottom-0 start-0 end-0" style="transform: scale(2);transform-origin: top center;color: #fff;">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>

 
</header>

<section class="py-4">


  <SaleHeaderTitle title="Apiladoras" />

  <div class="container mb-5 mt-5">
    <div class="row">

      <SaleCard>
        <span slot="title">RAYMOND / 740-DR32TT</span>
        <span slot="type">Apilador • ELÉCTRICO 36V</span>
        <span slot="description">El pantógrafo Raymond 740-DR32TT especial para pasillos estrechos y estantes altos, cuenta con una mayor visibilidad para el operador.</span>
        <span slot="specs">ALTURA MÁXIMA: 11M PESO MÁXIMO: 2040KG</span>
        <span slot="img"><img class="w-100 border-radius-md" src="../static/images/60be8297cb7b120.png"></span>
      </SaleCard>

      <SaleCard>
        <span slot="title">Presto PowerStak ™ / PPS2200</span>
        <span slot="type">Apilador • ELÉCTRICO 24V</span>
        <span slot="description">El Presto PowerStak ™ es un apilador de alto rendimiento y totalmente motorizado que proporciona flexibilidad y versatilidad instantáneas.</span>
        <span slot="specs">ALTURA MÁXIMA: 2.5M PESO MÁXIMO: 1000KG</span>
        <span slot="img"><img class="w-100 border-radius-md" src="../static/images/60be82f00cd3918.png"></span>
      </SaleCard>

      <SaleCard>
        <span slot="title">RAYMOND / ET-DR25TT - 2014</span>
        <span slot="type">Apilador • ELÉCTRICO 36V</span>
        <span slot="description">El pantógrafo Raymond ET-DR25TT especial para pasillos estrechos y estantes  de doble profundidad.</span>
        <span slot="specs">ALTURA MÁXIMA: 5.66M PESO MÁXIMO: 3.200KG</span>
        <span slot="img"><img class="w-100 border-radius-md" src="../static/images/60be853199108FOTOS PRODUCTOS DE LIFT (1).png"></span>
      </SaleCard>


      <SaleCard>
        <span slot="title">CROWN / RR - RD 5700</span>
        <span slot="type">Apilador • ELÉCTRICO 36V</span>
        <span slot="description">Los modelos retractiles RR/RD 5700 le entregan flexibilidad en aplicaciones selectivas y de doble profundidad.</span>
        <span slot="specs">ALTURA MÁXIMA: 10M PESO MÁXIMO: 2000KG</span>
        <span slot="img"><img class="w-100 border-radius-md" src="../static/images/60be86c42647dcrown-RD5700.png"></span>
      </SaleCard>

    </div>
  </div>

  <SaleHeaderTitle title="Montacargas" />

  <div class="container mb-5 mt-5">
    <div class="row">

      <SaleCard>
        <span slot="title">TOYOTA / 8FG</span>
        <span slot="type">Montacargas • DUAL (GAS / GASOLINA)</span>
        <span slot="description">Montacargas Toyota es excelente para el manejo de carga pesada, apilar y manipular materiales. Extraordinaria comodidad y visibilidad.</span>
        <span slot="specs">ALTURA MÁXIMA: 4.70M PESO MÁXIMO: 2500KG</span>
        <span slot="img"><img class="w-100 border-radius-md" src="../static/images/60be81fca1886TOYOTA 7FG.jpg"></span>
      </SaleCard>

      <SaleCard>
        <span slot="title">CROWN / FC 4500</span>
        <span slot="type">Montacargas • ELÉCTRICO 48V</span>
        <span slot="description">La serie FC consiste en un montacargas de operador sentado contrabalanceado con la mejor maniobrabilidad y resistencia.</span>
        <span slot="specs">ALTURA MÁXIMA: 7M PESO MÁXIMO: 2500KG</span>
        <span slot="img"><img class="w-100 border-radius-md" src="../static/images/60be859e000ef9227-9853560.jpg"></span>
      </SaleCard>

      <SaleCard>
        <span slot="title">CROWN / RC 5500</span>
        <span slot="type">Montacargas • ELECTRICO 36V</span>
        <span slot="description">Montacargas Crown contrabalanceado de 3 ruedas operador parado.</span>
        <span slot="specs">ALTURA MÁXIMA: 7M PESO MÁXIMO: 1500KG</span>
        <span slot="img"><img class="w-100 border-radius-md" src="../static/images/60c0d7b4356b4CROWN RC 5500.jpg"></span>
      </SaleCard>


      <SaleCard>
        <span slot="title">TOTOYA / FGZN30</span>
        <span slot="type">Montacargas • GAS PROPANO</span>
        <span slot="description">Toyota serie FGZN30 se destaca por tener el mejor diseño ergonómico, ayudando a reducir el cansancio del operador y contribuyendo a una operación confortable.</span>
        <span slot="specs">ALTURA MÁXIMA: 4.80M PESO MÁXIMO: 3000KG</span>
        <span slot="img"><img class="w-100 border-radius-md" src="../static/images/6137c0894a2e2Facebook LIFT.png"></span>
      </SaleCard>

      <SaleCard>
        <span slot="title">TOYOTA / FGZN25</span>
        <span slot="type">Montacargas • GAS PROPANO</span>
        <span slot="description">Toyota serie FGZN25 se destaca por tener el mejor diseño ergonómico, ayudando a reducir el cansancio del operador y contribuyendo a una operación confortable.</span>
        <span slot="specs">ALTURA MÁXIMA: 4.80M PESO MÁXIMO: 2500KG</span>
        <span slot="img"><img class="w-100 border-radius-md" src="../static/images/6137c11442410Toyota-presenta-en-México-sus-nuevos-montacargas-.png"></span>
      </SaleCard>

    </div>
  </div>

  <SaleHeaderTitle title="Tijeras Autopropulsadas" />

  <div class="container mb-5 mt-5">
    <div class="row">


      <SaleCard>
        <span slot="title">UPRIGHT / Modelo LX41</span>
        <span slot="type">Tijeras Autopropulsadas • DUAL (GAS / GASOLINA)</span>
        <span slot="description">La Scissor Lift  cuenta con controles proporcionales para maniobras rápidas y fáciles; sus dimensiones compactas le dan a esta máquina la capacidad de maniobrar en áreas estrechas.</span>
        <span slot="specs">ALTURA MÁXIMA: 12.30M PESO MÁXIMO: 680KG</span>
        <span slot="img"><img class="w-100 border-radius-md" src="../static/images/60be8457cbaca19.png"></span>
      </SaleCard>

    </div>
  </div>


  <SaleHeaderTitle title="Otros" />

  <div class="container mb-5 mt-5">
    <div class="row">

      <SaleCard>
        <span slot="title">WESCO INDUSTRIAL PRODUCTS / Modelo DM-1100</span>
        <span slot="type">Otros • -</span>
        <span slot="description">El Manipulador ergonómico WESCO DM-1100 puede levantar y transportar barriles con facilidad. La bomba hidráulica se levanta con mordazas accionadas por resorte que pueden sostener cualquier borde de tambor de 3/16 "o más.</span>
        <span slot="specs">ALTURA MÁXIMA: 1.40M PESO MÁXIMO: 500KG</span>
        <span slot="img"><img class="w-100 border-radius-md" src="../static/images/60be83489169a17.png"></span>
      </SaleCard>


      <SaleCard>
        <span slot="title">YALE / OS030BF</span>
        <span slot="type">Otros • ELÉCTRICO 24V</span>
        <span slot="description">YALE  OS030BF es especial para efectuar la recolección de pedidos de manera más fácil y precisa.</span>
        <span slot="specs">ALTURA MÁXIMA: 4.70M PESO MÁXIMO: 1360KG</span>
        <span slot="img"><img class="w-100 border-radius-md" src="../static/images/60c0d89b42cceYALE- OS030BF.jpg"></span>
      </SaleCard>

    </div>
  </div>
  
</section>
