<script>
  import { navigateTo } from "../util";
</script>

<!-- -------- START HEADER 10 w/ carousel ------- -->
<header>
  <div
    id="carouselExampleControls"
    class="carousel slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div
          class="page-header section-height-75"
          style="background-image: url(../static/img/carousel/montacargas1.jpeg);"
        >
          <span class="mask bg-gradient-dark"></span>
          <div class="container">
            <div class="row">
              <div class="col-lg-6 my-auto">
                <h4 class="text-white mb-0 fadeIn1 fadeInBottom">
                  Venta y alquiler
                </h4>
                <h1 class="text-white fadeIn2 fadeInBottom">
                  Trabaje con lo mejor
                </h1>
                <p class="lead text-white opacity-8 fadeIn3 fadeInBottom">
                  Conozca nuestra amplia variedad de equipos, contamos con las
                  mejores marcas, variedad de tonelajes y combustión.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div
          class="page-header section-height-75"
          style="background-image: url(../static/img/carousel/montacargas2.png);"
        >
          <span class="mask bg-gradient-dark"></span>
          <div class="container">
            <div class="row">
              <div class="col-lg-6 my-auto">
                <h4 class="text-white mb-0 fadeIn1 fadeInBottom">
                  Con nuestro equipo
                </h4>
                <h1 class="text-white fadeIn2 fadeInBottom">
                  Levantamos tu empresa
                </h1>
                <p class="lead text-white opacity-8 fadeIn3 fadeInBottom">
                  Contamos con equipos de diferentes tonelajes y capacidades,
                  permitiendo cumplir con sus necesidades.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div
          class="page-header section-height-75"
          style="background-image: url(../static/img/carousel/montacargas3.jpg);"
        >
          <span class="mask bg-gradient-dark"></span>
          <div class="container">
            <div class="row">
              <div class="col-lg-6 my-auto">
                <h4 class="text-white mb-0 fadeIn1 fadeInBottom">
                  Por eso somos
                </h4>
                <h1 class="text-white fadeIn2 fadeInBottom">
                  Los expertos en carga
                </h1>
                <p class="lead text-white opacity-8 fadeIn3 fadeInBottom">
                  Tenemos más de 17 años siendo los mejores en el alquiler y
                  venta de equipos especiales para la carga.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-height-75 position-absolute w-100 top-0">
      <a
        class="carousel-control-prev"
        href="#carouselExampleControls"
        role="button"
        data-bs-slide="prev"
      >
        <span
          class="carousel-control-prev-icon position-absolute bottom-50 ms-n7"
          aria-hidden="true"
        ></span>
        <span class="visually-hidden">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleControls"
        role="button"
        data-bs-slide="next"
      >
        <span
          class="carousel-control-next-icon position-absolute bottom-50 me-n7"
          aria-hidden="true"
        ></span>
        <span class="visually-hidden">Next</span>
      </a>
    </div>
  </div>
</header>
<!-- -------- END HEADER 11 w/ carousel ------- -->

<!-- -------- START Features w/ icons and text on left & gradient title and text on right -------- -->
<section class="">
  <div class="container py-5">
    <div class="row align-items-center">
      <div class="col-md-6 mb-md-0 mb-4">
        <h3 class="text-gradient text-primary mb-0">Somos FORKLIFT EQUIPMET</h3>
        <h3>El equipo que ayuda a levantar tu empresa.</h3>
        <p class="lead mb-md-5 mb-4">
          La empresa que facilita los procesos de carga al sector industrial y
          comercial en Costa Rica, mediante la venta y alquiler de equipo
          especializado como montacargas y apiladores, ofreciendo siempre el
          mejor servicio, tecnología y calidad.
          <a
            href="#"
            on:click={() => {
              navigateTo("about");
            }}
            class="text-primary icon-move-right"
            >Más acerca de nosotros
            <i class="fas fa-arrow-right text-sm ms-1"></i>
          </a>
        </p>

        <div class="p-3 info-horizontal">
          <div
            class="icon icon-shape rounded-circle bg-gradient-primary shadow text-center"
          >
            <i class="fas fa-user-check opacity-10"></i>
          </div>
          <div class="description ps-3">
            <p class="mb-0">
              Nuestro personal es altamente capacitado y con amplia experiencia
              en equipos de carga.
            </p>
          </div>
        </div>
        <div class="p-3 info-horizontal">
          <div
            class="icon icon-shape rounded-circle bg-gradient-primary shadow text-center"
          >
            <i class="fas fa-handshake opacity-10"></i>
          </div>
          <div class="description ps-3">
            <p class="mb-0">
              Servicio garantizado, puntualidad en los trabajos, respaldo,
              monitoreo continuo base-operador.
            </p>
          </div>
        </div>
        <div class="p-3 info-horizontal">
          <div
            class="icon icon-shape rounded-circle bg-gradient-primary shadow text-center"
          >
            <i class="fas fa-check-double opacity-10"></i>
          </div>
          <div class="description ps-3">
            <p class="mb-0">
              Buscamos la mejora continua del desempeño ambiental, de la
              calidad, la seguridad y salud en el trabajo, en cada actividad que
              realizamos.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class=" text-center">
          <img
            src="../static/img/APLILADOR3.png"
            alt="img-blur-shadow"
            class="img-fluid border-radius-lg max-height-600"
          />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- -------- END Features w/ icons and text on left & gradient title and text on right -------- -->
<!-- -------- START Features w/ image on left & title & text & 3 cols on soft background -------- -->
<section class="py-5">
  <div class="container-fluid px-0">
    <div class="row justify-content-center text-center">
      <div class="col-3 px-md-0"></div>
      <div class="col-12 position-relative">
        <div class="row bg-warning-soft bottom-0">
          <div class="row">
            <div class="col-xl-3 position-relative">
              <img
                class="w-100 border-radius-section border-top-start-radius-0 border-bottom-start-radius-0 start-0 position-absolute max-width-300 mt-n11 d-xl-block d-none"
                src="../static/img/montacargas.png"
                alt="image"
              />
            </div>
            <div class="col-xl-9">
              <div class="row">
                <div class="col-xl-8 col-md-8 col-12 text-left">
                  <div class="p-3 text-center border-radius-lg">
                    <h1 class="text-white">Venta y alquiler de equipo</h1>
                    <h4 class="text-white">Nuevos y/o usados</h4>
                    <p class="text-white text-lg mt-3">
                      <span class="font-weight-bold">Ponemos</span> a disponibilidad
                      las mejores marcas en montacargas, apiladoras, accesorios y
                      más.
                    </p>
                    <a
                      href="#"
                      on:click={() => {
                        navigateTo("sale");
                      }}
                      class="btn bg-gradient-primary btn-icon btn-lg"
                      type="button"
                    >
                      <div class="d-flex align-items-center">Ver Equipos</div>
                    </a>
                  </div>
                </div>
                <div class="col-xl-3"></div>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- -------- END Features w/ image on left & title & text & 3 cols on soft background -------- -->
<!-- -------   START PRE-FOOTER 6 - title & description and input    -------- -->
<div class="py-6">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 mx-auto text-center">
        <h3>Con la confianza de las mejores marcas</h3>
        <p class="lead">
          Contamos nos un amplio stock de equipos de las mejores marcas del
          mercado.
        </p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-2 col-md-4 col-6 mb-4 ms-auto">
        <img
          class="w-100 opacity-7"
          src="../static/img/logos/medium-logos/TOYOTA.svg"
        />
      </div>
      <div class="col-lg-2 col-md-4 col-6 mb-4">
        <img
          class="w-100 opacity-7"
          src="../static/img/logos/medium-logos/CROWN.svg"
        />
      </div>
      <div class="col-lg-2 col-md-4 col-6 mb-4">
        <img
          class="w-100 opacity-7"
          src="../static/img/logos/medium-logos/YALE.svg"
        />
      </div>
      <div class="col-lg-2 col-md-4 col-6 mb-4">
        <img
          class="w-100 opacity-7"
          src="../static/img/logos/medium-logos/GENIE.svg"
        />
      </div>
      <div class="col-lg-2 col-md-4 col-6 mb-4 me-auto">
        <img
          class="w-100 opacity-7"
          src="../static/img/logos/medium-logos/RAYMOND.svg"
        />
      </div>
    </div>
  </div>
</div>

<!-- -------- START PRE-FOOTER 8 w/ TEXT, BG IMAGE AND 2 BUTTONS ------- -->
<section
  class="my-4 py-5 bg-gradient-dark position-relative"
  style="background-image:url(../static/img/nastuh.jpg)"
>
  <span class="mask bg-gradient-dark opacity-8"></span>
  <div class="container position-relative z-index-2">
    <div class="row">
      <div class="col-lg-5 col-md-8 m-auto text-left">
        <h5 class="text-white mb-lg-0 mb-5">
          ¿SABE CUAL ES EL MEJOR EQUIPO PARA SU EMPRESA?
        </h5>
        <p class="text-white mb-lg-0 mb-5">
          Permítanos ayudarle, solicite nuestra asesoría GRATUITA
        </p>
      </div>
      <div class="col-lg-6 m-auto">
        <div class="row">
          <div class="col-sm-4 col-6 ps-sm-0 ms-auto">
            <a
              href="#"
              on:click={() => {
                navigateTo("contact");
              }}
              class="btn bg-gradient-warning mb-0 ms-lg-3 ms-sm-2 mb-sm-0 mb-2 me-auto w-100 d-block"
              >Contáctanos</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="my-4 py-5">
  <div class="container text-center">
    <h3 class="title">
      Nuestra empresa cuenta con certificación ISO 9001 Gestión de la
      Calidad.
    </h3>
    <img
      id="iso-certificate"
      src="/img/ISO/ISO-Lift.jpeg"
      alt="Rounded Image"
      class="img-rounded img-responsive mt-4"
      style="max-width: 300px; max-height: 300px"
    />
  </div>
</section>
