import Api from "./api.js";

export const getRequests = async (data) => {
  try {
    return await Api.get("/request", data);
  } catch (error) {
    return error.response;
  }
};

export const postRequest = async (data) => {
  try {
    return await Api.post("/request", data);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
