<section class="pt-3 pt-md-5 pb-md-5 pt-lg-7 bg-gray-200">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 mb-lg-0 mb-3">
        <div class="nav flex-column bg-white border-radius-lg p-3 position-sticky top-1">
          <li class="nav-item">
            <a class="nav-link" data-scroll="" href="#general-terms">
              <div class="icon me-2">
                <svg class="text-primary" width="16px" height="16px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>spaceship</title>
                  <g id="Basic-Elements" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Rounded-Icons" transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fill-rule="nonzero">
                      <g id="Icons-with-opacity" transform="translate(1716.000000, 291.000000)">
                        <g id="spaceship" transform="translate(4.000000, 301.000000)">
                          <path class="color-background" d="M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z" id="Shape"></path>
                          <path class="color-background" d="M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z" id="Path"></path>
                          <path class="color-background" d="M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z" id="color-2" opacity="0.598539807"></path>
                          <path class="color-background" d="M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z" id="color-3" opacity="0.598539807"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              Aviso legal
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-scroll="" href="#privacidad">
              <div class="icon me-2">
                <svg class="text-primary" width="16px" height="16px" viewBox="0 0 40 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>document</title>
                  <g id="Basic-Elements" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Rounded-Icons" transform="translate(-1870.000000, -591.000000)" fill="#FFFFFF" fill-rule="nonzero">
                      <g id="Icons-with-opacity" transform="translate(1716.000000, 291.000000)">
                        <g id="document" transform="translate(154.000000, 300.000000)">
                          <path class="color-background" d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z" id="Path" opacity="0.603585379"></path>
                          <path class="color-background" d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z" id="Shape"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              Política privacidad
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-scroll="" href="#cookies">
              <div class="icon me-2">
                <svg class="text-primary" width="16px" height="16px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>box-3d-50</title>
                  <g id="Basic-Elements" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Rounded-Icons" transform="translate(-2319.000000, -291.000000)" fill="#FFFFFF" fill-rule="nonzero">
                      <g id="Icons-with-opacity" transform="translate(1716.000000, 291.000000)">
                        <g id="box-3d-50" transform="translate(603.000000, 0.000000)">
                          <path class="color-background" d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z" id="Path"></path>
                          <path class="color-background" d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z" id="Path" opacity="0.7"></path>
                          <path class="color-background" d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z" id="Path" opacity="0.7"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              Política de cookies
            </a>
          </li>
          
        </div>
      </div>
      <div class="col-lg-9">
        <div class="card shadow-lg mb-5">
          <div class="card-header bg-gradient-primary p-5 position-relative">
            <h3 class="text-white mb-0">Términos y condiciones</h3>
            <p class="text-white opacity-8 mb-4">Última modificación: Mar 18 2021</p>
            <div class="position-absolute w-100 z-index-1 bottom-0 ms-n5">
              <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" shape-rendering="auto" style="height:7vh;min-height:50px;">
                <defs>
                  <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                </defs>
                <g class="moving-waves">
                  <use xlink:href="#gentle-wave" x="48" y="-1" fill="rgba(255,255,255,0.40"></use>
                  <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                  <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                  <use xlink:href="#gentle-wave" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                  <use xlink:href="#gentle-wave" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                  <use xlink:href="#gentle-wave" x="48" y="16" fill="rgba(255,255,255,0.95"></use>
                </g>
              </svg>
            </div>
          </div>
          <div class="card-body p-5">
            <h3 class="mt-5 mb-3" id="general-terms">AVISO LEGAL Y TÉRMINOS DE USO</h3>
            <p>En este espacio, el USUARIO, podrá encontrar toda la información relativa a los términos y condiciones legales que definen las relaciones entre los usuarios y nosotros como responsables de esta web. Como usuario, es importante que conozcas estos términos antes de continuar tu navegación. .Como responsable de esta web, asume el compromiso de procesar la información de nuestros usuarios y clientes con plenas garantías y cumplir con los requisitos nacionales y europeos que regulan la recopilación y uso de los datos personales de nuestros usuarios.</p>
            <br>
            <p><strong>CONDICIONES GENERALES DE USO</strong></p>
            <p>Las presentes Condiciones Generales regulan el uso (incluyendo el mero acceso) de las páginas de la web, integrantes del sitio web de incluidos los contenidos y servicios puestos a disposición en ellas. Toda persona que acceda a la web, (“Usuario”) acepta someterse a las Condiciones Generales vigentes en cada momento del portal.</p>
            <br>
            <p><strong>COMPROMISOS Y OBLIGACIONES DE LOS USUARIOS</strong></p>
            <p>El Usuario queda informado, y acepta, que el acceso a la presente web no supone, en modo alguno, el inicio de una relación comercial con Forklift Equipment S.A. De esta forma, el usuario se compromete a utilizar el sitio Web, sus servicios y contenidos sin contravenir la legislación vigente, la buena fe y el orden público. Queda prohibido el uso de la web, con fines ilícitos o lesivos, o que, de cualquier forma, puedan causar perjuicio o impedir el normal funcionamiento del sitio web. Respecto de los contenidos de esta web, se prohíbe: Su reproducción, distribución o modificación, total o parcial, a menos que se cuente con la autorización de sus legítimos titulares; Cualquier vulneración de los derechos del prestador o de los legítimos titulares; Su utilización para fines comerciales o publicitarios.</p>
            <p>En la utilización de la web, el Usuario se compromete a no llevar a cabo ninguna conducta que pudiera dañar la imagen, los intereses y los derechos de o de terceros o que pudiera dañar, inutilizar o sobrecargar el portal (www.liftmontacargas.com) o que impidiera, de cualquier forma, la normal utilización de la web. No obstante, el Usuario debe ser consciente de que las medidas de seguridad de los sistemas informáticos en Internet no son enteramente fiables y que, por tanto no puede garantizar la inexistencia de virus u otros elementos que puedan producir alteraciones en los sistemas informáticos (software y hardware) del Usuario o en sus documentos electrónicos y ficheros contenidos en los mismos.</p>
            <br>
            <p><strong>MEDIDAS DE SEGURIDAD</strong></p>
            <p>Los datos personales comunicados por el usuario  pueden ser almacenados en bases de datos automatizadas o no, cuya titularidad corresponde en exclusiva al titular del dominio www.liftmontacargas.com , asumiendo ésta todas las medidas de índole técnica, organizativa y de seguridad que garantizan la confidencialidad, integridad y calidad de la información contenida en las mismas de acuerdo con lo establecido en la normativa vigente en protección de datos. La comunicación entre los usuarios y www.liftmontacargas.com utiliza un canal seguro, y los datos transmitidos son cifrados gracias a protocolos a https, por tanto, garantizamos las mejores condiciones de seguridad para que la confidencialidad de los usuarios esté garantizada.</p>
            <br>
            <p><strong>RECLAMACIONES</strong></p>
            <p>Informa que existen hojas de reclamación a disposición de usuarios y clientes. El Usuario podrá realizar reclamaciones solicitando su hoja de reclamación o remitiendo un correo electrónico a&nbsp;indicando su nombre y apellidos, el servicio y/o producto adquirido y exponiendo los motivos de su reclamación.</p>
            <p>El usuario/comprador podrá notificarnos la reclamación, bien a través de correo electrónico a: mercadeo@liftmontacargas.com&nbsp;, si lo desea adjuntando el siguiente formulario de reclamación: El servicio/producto: Adquirido el día: Nombre del usuario: Domicilio del usuario: Firma del usuario (solo si se presenta en papel): Fecha: Motivo de la reclamación:</p>
            <br>
            <p><strong>DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</strong></p>
            <p>Quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de Forklift Equipment S.A. El usuario se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de Forklift Equipment S.A. El usuario conoce y acepta que la totalidad del sitio web, conteniendo sin carácter exhaustivo el texto, software, contenidos (incluyendo estructura, selección, ordenación y presentación de los mismos) podcast, fotografías, material audiovisual y gráficos, está protegida por marcas, derechos de autor y otros derechos legítimos, de acuerdo con los tratados internacionales en los que Costa Rica es parte y otros derechos de propiedad y leyes de Costa Rica. En el caso de que un usuario o un tercero consideren que se ha producido una violación de sus legítimos derechos de propiedad intelectual por la introducción de un determinado contenido en la web, deberá notificar dicha circunstancia a Forklift Equipment S.A indicando:</p>
            <ul>
              <li>Datos personales del interesado titular de los derechos presuntamente infringidos, o indicar la representación con la que actúa en caso de que la reclamación la presente un tercero distinto del interesado.</li>
              <li>Señalar los contenidos protegidos por los derechos de propiedad intelectual y su ubicación en la web, la acreditación de los derechos de propiedad intelectual señalados y declaración expresa en la que el interesado se responsabiliza de la veracidad de las informaciones facilitadas en la notificación.</li>
            </ul>
            <br>
            <p><strong>ENLACES EXTERNOS</strong></p>
            <p>Las páginas de la web, podrían proporcionar enlaces a otros sitios web propios y contenidos que son propiedad de terceros. El único objeto de los enlaces es proporcionar al Usuario la posibilidad de acceder a dichos enlaces. no se responsabiliza en ningún caso de los resultados que puedan derivarse al Usuario por acceso a dichos enlaces. Asimismo, el usuario encontrará dentro de este sitio, páginas, promociones, programas de afiliados que acceden a los hábitos de navegación de los usuarios para establecer perfiles. Esta información siempre es anónima y no se identifica al usuario.</p>
            <p>La Información que se proporcione en estos Sitios patrocinados o enlaces de afiliados está sujeta a las políticas de privacidad que se utilicen en dichos Sitios y no estará sujeta a esta política de privacidad. Por lo que recomendamos ampliamente a los Usuarios a revisar detalladamente las políticas de privacidad de los enlaces de afiliado. El Usuario que se proponga establecer cualquier dispositivo técnico de enlace desde su sitio web al portal deberá obtener la autorización previa y escrita de El establecimiento del enlace no implica en ningún caso la existencia de relaciones entre y el propietario del sitio en el que se establezca el enlace, ni la aceptación o aprobación por parte de de sus contenidos o servicios.</p>
            <br>
            <p><strong>POLÍTICA DE COMENTARIOS</strong></p>
            <p>En nuestra web se permiten realizar comentarios para enriquecer los contenidos y realizar consultas. No se admitirán comentarios que no estén relacionados con la temática de esta web, que incluyan difamaciones, agravios, insultos, ataques personales o faltas de respeto en general hacia el autor o hacia otros miembros. También serán suprimidos los comentarios que contengan información que sea obviamente engañosa o falsa, así como los comentarios que contengan información personal, como, por ejemplo, domicilios privado o teléfonos y que vulneren nuestra política de protección de datos. Se desestimará, igualmente, aquellos comentarios creados sólo con fines promocionales de una web, persona o colectivo y todo lo que pueda ser considerado spam en general. No se permiten comentarios anónimos, así como aquellos realizados por una misma persona con distintos apodos. No se considerarán tampoco aquellos comentarios que intenten forzar un debate o una toma de postura por otro usuario.</p>
            <br>
            <p><strong>EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</strong></p>
            <p>El Prestador no otorga ninguna garantía ni se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran traer causa de:</p>
            <ul>
              <li>La falta de disponibilidad, mantenimiento y efectivo funcionamiento de la web, o de sus servicios y contenidos.</li>
              <li>La existencia de virus, programas maliciosos o lesivos en los contenidos.</li>
              <li>El uso ilícito, negligente, fraudulento o contrario a este Aviso Legal.</li>
              <li>La falta de licitud, calidad, fiabilidad, utilidad y disponibilidad de los servicios prestados por terceros y puestos a disposición de los usuarios en el sitio web.</li>
              <li>El prestador no se hace responsable bajo ningún concepto de los daños que pudieran dimanar del uso ilegal o indebido de la presente página web.</li>               
            </ul>
            <br>
            <p><strong>LEY APLICABLE Y JURISDICCIÓN</strong></p>
            <p>Con carácter general las relaciones entre con los Usuarios de sus servicios telemáticos, presentes en esta web se encuentran sometidas a la legislación y jurisdicción costarricenses y a los tribunales.</p>
            <br>
            <p><strong>CONTACTO</strong></p>
            <p>En caso de que cualquier Usuario tuviese alguna duda acerca de estas Condiciones legales o cualquier comentario sobre el portal , por favor diríjase a: mercadeo@liftmontacargas.com </p>
            <br>
            <p>De parte del equipo que formamos te agradecemos el tiempo dedicado en leer este Aviso Legal.</p>
            <br>

            <h3 class="mt-5 mb-3" id="privacidad">Política de privacidad</h3>
            <p><strong>Finalidades – ¿con qué finalidades tratamos tus datos?</strong></p>
            <p>Te informamos de que trataremos los datos que nos facilitas para:</p>
            <ul>
              <li>Gestionar la contratación de servicios que realice a través de la Plataforma, así como la facturación y entrega correspondiente.</li>
              <li>Remitir periódicamente comunicaciones sobre servicios, eventos y noticias relacionadas con las actividades desarrolladas por Forklift Equipment S.A, por cualquier medio (teléfono, correo postal o email), salvo que se indique lo contrario o el usuario se oponga o revoque su consentimiento.</li>
              <li>Remitir información comercial y / o promocional relacionada con el sector de servicios contratados y valor añadido para usuarios finales, salvo que se indique lo contrario o el usuario se oponga o revoque su consentimiento.</li>
              <li>Dar cumplimiento a las obligaciones legalmente establecidas, así como verificar el cumplimiento de las obligaciones contractuales, incluía la prevención de fraude.</li>
              <li>Cesión de datos a organismos y autoridades, siempre y cuando sean requeridos de conformidad con las disposiciones legales y reglamentarias.</li>
            </ul>
            <br>
            <p><strong>Categorías de datos – ¿Qué datos tratamos?</strong></p>
            <p>Derivada de las finalidades antes mencionadas, gestionamos las siguientes categorías de datos:</p>
            <ul>
              <li>Datos identificativos.</li>
              <li>Metadatos de comunicaciones electrónicas.</li>
              <li>Datos de información comercial. En caso de que el usuario facilite datos de terceros, manifiesta contar con el consentimiento de estos y se compromete a trasladarle la información contenida en esta cláusula, eximiendo a de cualquier responsabilidad en este sentido.</li>
              <li>No obstante, podrá llevar a cabo las verificaciones para constatar este hecho, adoptando las medidas de diligencia debida que correspondan, conforme a la normativa de protección de datos.</li>
            </ul>
            <br>
            <p><strong>Legitimación – ¿cuál es la legitimación para el tratamiento de tus datos?</strong></p>
            <p>El tratamiento de datos cuyo fin es el envío de boletines periódicos (newslettering) sobre servicios, eventos y noticias relacionadas con nuestra actividad profesional se basa en el consentimiento del interesado, solicitado expresamente para llevar a cabo dichos tratamientos, de acuerdo con la normativa vigente. Además, la legitimación para el tratamiento de los datos relacionados con ofertas o colaboraciones se basan en el consentimiento del usuario que remite sus datos, que puede retirar en cualquier momento, si bien ello puede afectar a la posible comunicación de forma fluida y obstrucción de procesos que desea realizar.</p>
            <br>
            <p><strong>Plazo de Conservación de los Datos – ¿Por cuánto tiempo conservaremos tus dato</strong></p>
            <p>www.liftmontacargas.com conservará los datos personales de los usuarios únicamente durante el tiempo necesario para la realización de las finalidades para las que fueron recogidos, mientras no revoque los consentimientos otorgados. Posteriormente, en caso de ser necesario, mantendrá la información bloqueada durante los plazos legalmente establecidos.</p>
            <br>
            <p><strong>Destinatarios ¿A qué destinatarios se comunicarán tus datos?</strong></p>
            <p>Tus datos podrán ser accedidos por aquellos proveedores que prestan servicios a www.liftmontacargas.com, tales como servicios de alojamiento, herramientas de marketing y sistemas de contenido u otros profesionales, cuando dicha comunicación sea necesaria normativamente, o para la ejecución de los servicios contratados.</p>
            <p>www.liftmontacargas.com, ha suscrito los correspondientes contratos de encargo de tratamiento con cada uno de los proveedores que prestan servicios a www.liftmontacargas.com , con el objetivo de garantizar que dichos proveedores tratarán tus datos de conformidad con lo establecido en la legislación vigente.</p>
            <p>También podrán ser cedidos a las Fuerzas y Cuerpos de Seguridad del Estado en los casos que exista una obligación legal. Bancos y entidades financieras, para el cobro de los servicios. Administraciones públicas con competencia en los sectores de actividad, cuando así lo establezca la normativa vigente.</p>
            <br>
            <p><strong>Seguridad de la Información – ¿Qué medidas de seguridad implantamos para cuidar sus datos?</strong></p>
            <p>Para proteger las diferentes tipologías de datos reflejados en esta política de privacidad llevará a cabo las medidas de seguridad técnicas necesarias para evitar su pérdida, manipulación, difusión o alteración.</p>
            <ul>
              <li>Encriptación de las comunicaciones entre el dispositivo del usuario y los servidores de www.liftmontacargas.com.</li>
              <li>Encriptación de la información en los propios servidores de www.liftmontacargas.com.</li>
              <li>Otras medidas que eviten el acceso a los datos del usuario por parte de terceros.</li>
              <li>En aquellos casos en los que cuente con prestadores de servicio para el mantenimiento de la plataforma que se encuentren fuera de la Costa Rica, estas trasferencias internacionales se hayan regularizadas atendiendo al compromiso de con la protección, integridad y seguridad de los datos personales de los usuarios.</li>
            </ul>
            <br>
            <p><strong>Derechos – ¿Cuáles son tus derechos cuando nos facilitas tus datos y cómo puedes ejercerlos?</strong></p>
            <p>Tienes derecho a obtener confirmación sobre si en www.liftmontacargas.com estamos tratando datos personales que te conciernan, o no. Asimismo, tienes derecho a acceder a tus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.</p>
            <p>En determinadas circunstancias, podrás solicitar la limitación del tratamiento de tus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones. En determinadas circunstancias y por motivos relacionados con tu situación particular, podrás oponerte al tratamiento de tus datos. dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.</p>
            <p>Asimismo, puedes ejercer el derecho a la portabilidad de los datos, así como retirar los consentimientos facilitados en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.</p>
            <p>Si deseas hacer uso de cualquiera de tus derechos puede dirigirse a mercadeo@liftmontacargas.com.</p>
            <br>
            <p><strong>Modificación de la política de privacidad</strong></p>
            <p>www.liftmontacargas.com podrá modificar la presente Política de Privacidad en cualquier momento, siendo publicadas las sucesivas versiones en el Sitio Web. En cualquier caso, comunicará con previo aviso las modificaciones de la presente política que afecten a los usuarios a fin de que puedan aceptar las mismas.</p>
            <p>La presente Política de Privacidad se encuentra actualizada a fecha 14/03/2021 (Costa Rica). Reservados todos los derechos.</p>
            <br>

            <h3 class="mt-5 mb-3" id="cookies">Política de cookies</h3>
            <p>En esta web se utilizan cookies de terceros y propias para conseguir que tengas una mejor experiencia de navegación, puedas compartir contenido en redes sociales y para que podamos obtener estadísticas de los usuarios.</p>
            <p>Puedes evitar la descarga de cookies a través de la configuración de tu navegador, evitando que las cookies se almacenen en su dispositivo.</p>
            <p>Como propietario de este sitio web, te comunico que no utilizamos ninguna información personal procedente de cookies, tan sólo realizamos estadísticas generales de visitas que no suponen ninguna información personal.</p>
            <p>Es muy importante que leas la presente política de cookies y comprendas que, si continúas navegando, consideraremos que aceptas su uso.</p>
            <p>Si continúas navegando, estarás prestando tu consentimiento para el empleo de los referidos mecanismos.</p>   
            <br>          
            <p><strong>Entidad Responsable</strong></p>
            <p>La entidad responsable de la recogida, procesamiento y utilización de tus datos personales, es la página www.liftmontacargas.com, propiedad de Forklift Equipment S.A.</p>
            <br>
            <p><strong>¿Qué son las cookies?</strong></p>
            <p>Las cookies son un conjunto de datos que un servidor deposita en el navegador del usuario para recoger la información de registro estándar de Internet y la información del comportamiento de los visitantes en un sitio web. Es decir, se trata de pequeños archivos de texto que quedan almacenados en el disco duro del ordenador y que sirven para identificar al usuario cuando se conecta nuevamente al sitio web. Su objetivo es registrar la visita del usuario y guardar cierta información. Su uso es común y frecuente en la web ya que permite a las páginas funcionar de manera más eficiente y conseguir una mayor personalización y análisis sobre el comportamiento del usuario.</p>
            <br>
            <p><strong>¿Qué tipos de cookies existen?</strong></p>
            <p>Las cookies utilizadas en nuestro sitio web, son de sesión y de terceros, y nos permiten almacenar y acceder a información relativa al idioma, el tipo de navegador utilizado, y otras características generales predefinidas por el usuario, así como, seguir y analizar la actividad que lleva a cabo, con el objeto de introducir mejoras y prestar nuestros servicios de una manera más eficiente y personalizada.</p>
            <p>Las cookies, en función de su permanencia, pueden dividirse en cookies de sesión o permanentes. Las que expiran cuando el usuario cierra el navegador. Las que expiran en función de cuando se cumpla el objetivo para el que sirven (por ejemplo, para que el usuario se mantenga identificado en los servicios de www.liftmontacargas.com ) o bien cuando se borran manualmente.</p>
            <p>Nombre: __utma / Tipo: De Terceros (Google Analytics) / Caducidad: 2 años / Finalidad: Se usa para distinguir usuarios y sesiones. / Clase: No Exenta</p>
            <p>Nombre: __utmb / Tipo: De Terceros (Google Analytics) / Caducidad: 30 minutos / Finalidad: Se usa para determinar nuevas sesiones o visitas. / Clase: No Exenta</p>
            <p>Nombre: __utmc / Tipo: De Terceros (Google Analytics) / Caducidad: Al finalizar la sesión / Finalidad: Se configura para su uso con Urchin. / Clase: No Exenta</p>
            <p>Nombre: __utmz / Tipo: De Terceros (Google Analytics) / Caducidad: 6 meses / Finalidad: Almacena el origen o la campaña que explica cómo el usuario ha llegado hasta la página web. / Clase: No Exenta</p>
            <br>
            <p>Adicionalmente, en función de su objetivo, las cookies pueden clasificarse de la siguiente forma:</p>
            <br>
            <p><strong>Cookies de rendimiento</strong></p>
            <p>Este tipo de Cookie recuerda sus preferencias para las herramientas que se encuentran en los servicios, por lo que no tiene que volver a configurar el servicio cada vez que usted visita. A modo de ejemplo, en esta tipología se incluyen: Ajustes de volumen de reproductores de vídeo o sonido. Las velocidades de transmisión de vídeo que sean compatibles con su navegador. Los objetos guardados en el “carrito de la compra” en los servicios de e-commerce tales como tiendas.</p>
            <br>
            <p><strong>Cookies de geo-localización</strong></p>
            <p>Estas cookies son utilizadas para averiguar en qué país se encuentra cuando se solicita un servicio. Esta cookie es totalmente anónima, y sólo se utiliza para ayudar a orientar el contenido a su ubicación.</p>
            <br>
            <p><strong>Cookies de registro</strong></p>
            <p>Las cookies de registro se generan una vez que el usuario se ha registrado o posteriormente ha abierto su sesión, y se utilizan para identificarle en los servicios con los siguientes objetivos:</p>
            <p>Mantener al usuario identificado de forma que, si cierra un servicio, el navegador o el ordenador y en otro momento u otro día vuelve a entrar en dicho servicio, seguirá identificado, facilitando así su navegación sin tener que volver a identificarse. Esta funcionalidad se puede suprimir si el usuario pulsa la funcionalidad [cerrar sesión], de forma que esta cookie se elimina y la próxima vez que entre en el servicio el usuario tendrá que iniciar sesión para estar identificado.</p>
            <p>Comprobar si el usuario está autorizado para acceder a ciertos servicios, por ejemplo, para participar en un concurso.</p>
            <p>Adicionalmente, algunos servicios pueden utilizar conectores con redes sociales tales como Facebook o Twitter. Cuando el usuario se registra en un servicio con credenciales de una red social, autoriza a la red social a guardar una Cookie persistente que recuerda su identidad y le garantiza acceso a los servicios hasta que expira. El usuario puede borrar esta Cookie y revocar el acceso a los servicios mediante redes sociales actualizando sus preferencias en la red social que específica.</p>
            <br>
            <p><strong>Cookies de analíticas</strong></p>
            <p>Cada vez que un usuario visita un servicio, una herramienta de un proveedor externo genera una cookie analítica en el ordenador del usuario. Esta cookie que sólo se genera en la visita, servirá en próximas visitas a los servicios de para identificar de forma anónima al visitante. Los objetivos principales que se persiguen son:</p>
            <p>Permitir la identificación anónima de los usuarios navegantes a través de la cookie (identifica navegadores y dispositivos, no personas) y por lo tanto la contabilización aproximada del número de visitantes y su tendencia en el tiempo. Identificar de forma anónima los contenidos más visitados y por lo tanto más atractivos para los usuarios Saber si el usuario que está accediendo es nuevo o repite visita.</p>
            <p>Importante: Salvo que el usuario decida registrarse en un servicio de www.liftmontacargas.com, la cookie nunca irá asociada a ningún dato de carácter personal que pueda identificarle. Dichas cookies sólo serán utilizadas con propósitos estadísticos que ayuden a la optimización de la experiencia de los usuarios en el sitio.</p>
            <br>
            <p><strong>Cookies de publicidad</strong></p>
            <p>Este tipo de cookies permiten ampliar la información de los anuncios mostrados a cada usuario anónimo en los servicios de www.liftmontacargas.com. Entre otros, se almacena la duración o frecuencia de visualización de posiciones publicitarias, la interacción con las mismas, o los patrones de navegación y/o comportamientos del usuario ya que ayudan a conformar un perfil de interés publicitario. De este modo, permiten ofrecer publicidad afín a los intereses del usuario.</p>
            <br>
            <p><strong>Cookies publicitarias de terceros</strong></p>
            <p>Además de la publicidad gestionada por las webs de en sus servicios, las webs de ofrecen a sus anunciantes la opción de servir anuncios a través de terceros (“Ad-Servers”). De este modo, estos terceros pueden almacenar cookies enviadas desde los servicios de procedentes de los navegadores de los usuarios, así como acceder a los datos que en ellas se guardan.</p>
            <p>Las empresas que generan estas cookies tienen sus propias políticas de privacidad. En la actualidad, las webs de utilizan la plataforma Doubleclick (Google) para gestionar estos servicios.</p>
            <br>
            <p><strong>¿Cómo puedo deshabilitar las cookies en mi navegador?</strong></p>
            <p>Se pueden configurar los diferentes navegadores para avisar al usuario de la recepción de cookies y, si se desea, impedir su instalación en el equipo. Asimismo, el usuario puede revisar en su navegador qué cookies tiene instaladas y cuál es el plazo de caducidad de las mismas, pudiendo eliminarlas.</p>
            <p>Para ampliar esta información consulte las instrucciones y manuales de su navegador:</p>
            <p>Para más información sobre la administración de las cookies en Google Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=es">Clic aqui</a></p>
            <p>Para más información sobre la administración de las cookies en Internet Explorer: <a href="http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-questions">Clic aqui</a></p>
            <p>Para más información sobre la administración de las cookies en Mozilla Firefox:  <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">Clic aqui</a></p>
            <p>Para más información sobre la administración de las cookies en Safari: <a href="http://www.apple.com/es/privacy/use-of-cookies/">Clic aqui</a></p>
            <p>Para más información sobre la administración de las cookies en Opera: <a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">Clic aqui</a></p>
            <p>Si desea dejar de ser seguido por Google Analytics visite: <a href="http://tools.google.com/dlpage/gaoptout">Clic aqui</a></p>
            <br>
            <p><strong>Para saber más sobre las cookies</strong></p>
            <p>Puede obtener más información sobre la publicidad online basada en el comportamiento y la privacidad online en el siguiente enlace: <a href="http://www.youronlinechoices.com/es/">http://www.youronlinechoices.com/es/</a></p>
            <p>Protección de datos de Google Analytics: <a href="http://www.google.com/analytics/learn/privacy.html">http://www.google.com/analytics/learn/privacy.html</a></p>
            <p>Cómo usa Google Analytics las cookies: <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es#analyticsjs">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es#analyticsjs</a></p>
            <br>
            <p><strong>¿Cómo puedo deshabilitar las cookies en mi navegador?</strong></p>
            <p>Las webs de pueden modificar esta Política de Cookies en función de exigencias legislativas, reglamentarias, o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos, por ello se aconseja a los usuarios que la visiten periódicamente.</p>
            <p>Cuando se produzcan cambios significativos en esta Política de Cookies, estos se comunicarán a los usuarios bien mediante la web o a través de correo electrónico a los usuarios registrados.</p>
            <br>
            
            
          </div>
        </div>
      </div>
    </div>
  </div>
</section>