// import { writable } from 'svelte/store';

import Home from './views/Home.svelte';
import Services from './views/Services.svelte';
import _404 from './views/404.svelte';
import About from './views/About.svelte';
import LegalTerms from './views/LegalTerms.svelte';
import Contact from './views/Contact.svelte';
import Sale from './components/Sale.svelte';
// import { Cookie } from './util';
// import { validateSession } from './services/login';

// import _ from "lodash";

export const MainTarget = "main";

export const VIEWS = {
    '404': _404,
    'sale': Sale,
    'about': About,
    'conditions': LegalTerms,
    'contact': Contact,
    'home': Home
}

// export const SESSION = {
//     set: (value, expires = 14) => {
//         Cookie.set("session", value, expires);
//     },
//     get: () => {
//         return JSON.parse(Cookie.get('session'));
//     },
//     isValid: async () => {

//         let valid;
//         let cookie = SESSION.get();
//         if (!_.isEmpty(cookie)) {
//             valid = await validateSession();
//         } else {
//             valid = false;
//         }
//         return valid;

//     }
// }


// export const createWritableStore = (key, startValue) => {
//     const { subscribe, set } = writable(startValue)

//     return {
//         subscribe,
//         set,
//         useLocalStorage: () => {
//             const json = Cookie.get(key);
//             console.log(json);
//             if(json){
//                 set(JSON.parse(json));
//             }

//             subscribe(current => {
//                 Cookie.set(key, JSON.stringify(current))
//             });
//         }
//     }
// }

// export const session = createWritableStore('session', {});