<script>
  import RequestForm from "../components/Request/RequestForm.svelte";

  export let request_details;
  let name, phone, email, details, form;
  let sending = false;
  let RQF;
  let modal;
  


  if (request_details) {
    details = "";
    details += "Cotizacion para: "+request_details;
  }

  const hide = () => {
    let _modal = new bootstrap.Modal(modal, {});
    _modal.hide();
  }
  export const show = () => {
    let _modal = new bootstrap.Modal(modal, {});
    _modal.show()
  }
</script>

<RequestForm bind:this={RQF} bind:name bind:phone bind:email bind:details bind:form bind:sending />


<div
bind:this={modal}
class="modal fade"
tabindex="-1"
aria-labelledby="cotizarmodal"
aria-hidden="true"
>
<div
  class="modal-dialog modal-danger modal-dialog-centered modal-"
  role="document"
  contenteditable="false"
>
  <div class="modal-content">
    <div class="modal-body p-0">
      <div class="card card-plain">
        <div class="card-header pb-0 text-left">
            <slot name="modalHeader">
              <h3 class="font-weight-bolder text-primary text-gradient">
                Cotizar
              </h3>
              <p class="mb-0">
                Complete el formulario y nuestro equipo se comunicará con usted
                dentro de las 24 horas.
              </p>
            </slot>
        </div>
        <div class="card-body pb-3">
          <form
            role="form text-left"
            id="contact-form"
          >
            <label>Nombre completo</label>
            <div class="input-group mb-3">
              <input
                type="text"
                name="name"
                class="form-control"
                aria-label="Nombre completo..."
                placeholder="Nombre completo"
                required
                bind:value={name}
              />
            </div>
            <label>Número de teléfono</label>
            <div class="input-group mb-3">
              <input
                type="tel"
                name="phone"
                class="form-control"
                aria-label="Número de teléfono"
                placeholder="Número de teléfono"
                required
                bind:value={phone}
              />
            </div>
            <label>Correo electrónico</label>
            <div class="input-group mb-3">
              <input
                type="email"
                name="email"
                class="form-control"
                placeholder="Correo electrónico"
                required
                bind:value={email}
              />
            </div>
            <label>Detalle de la cotización</label>
            <div class="input-group mb-3">
              <textarea
                name="message"
                class="form-control"
                id="message"
                rows="4"
                placeholder="Detalle"
                required
                bind:value={details}
              />
            </div>
            <div class="text-center">
                <button
                disabled={sending}
                type="button"
                on:click={() => RQF.submit(hide)}
                class="btn btn-danger pull-right contact-submit-btn"
              >
                <span>
                  {#if sending}
                    <img
                      src="../static/img/btn-loading.svg"
                      width="20"
                      height="20"
                      alt="Loading..."
                    />
                  {:else}
                    Enviar mensaje
                  {/if}
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
