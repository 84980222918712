<script>
  import RequestModal from "../RequestModal.svelte";
  
  let modal;

</script>

<div class="col-lg-6 col-12" contenteditable="false">
    <div class="card card-profile overflow-hidden">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12 pe-lg-0">
          <a href="javascript:;">
            <div class="p-3 pe-0">
                <slot name="img">
                    <img class="w-100 border-radius-md" src="../static/images/60be8297cb7b120.png">
                </slot>
            </div>
          </a>
        </div>
        <div class="col-lg-8 col-md-6 col-12 ps-lg-0 my-auto">
          <div class="card-body">
            <h5 class="mb-0"><slot name="title" /></h5>
            <h6 class="text-info"><slot name="type" /></h6>
            <p class="mb-0"><slot name="description" /></p>
            <p class="mb-0"><slot name="specs" /></p>
            <button type="button" class="btn bg-gradient-warning mb-0 ms-lg-3 ms-sm-2 mb-sm-0 mb-2 me-auto w-100 d-block" on:click={() => modal.show()}>
              Cotizar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

<RequestModal bind:this={modal}>
    <div slot="modalHeader">
        <h3 class="font-weight-bolder text-primary text-gradient"><slot name="title" /></h3>
        <p class="mb-0"><slot name="type" /></p>
        <p class="mb-0"><slot name="description" /></p>
        <p class="mb-0"><slot name="specs" /></p>
    </div>
</RequestModal>