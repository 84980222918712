<script>
  import RequestForm from "../components/Request/RequestForm.svelte";
  let name, phone, email, details, form;
  let sending = false;
  let RQF;
</script>

<RequestForm bind:this={RQF} bind:name bind:phone bind:email bind:details bind:form bind:sending />

<header>
  <div
    class="page-header section-height-75"
    style="background-image: url(../static/img/2.jpg)"
  >
    <span class="mask bg-gradient-dark" />
    <div class="container">
      <div class="row">
        <div class="col-lg-8 mx-auto text-white text-center">
          <h2 class="text-white">Contáctanos</h2>
          <p class="lead">
            ¿Alguna pregunta o comentario? ¡Solo escríbenos un mensaje!
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    class="position-relative overflow-hidden"
    style="height:36px;margin-top:-35px;"
  >
    <div
      class="w-full absolute bottom-0 start-0 end-0"
      style="transform: scale(2);transform-origin: top center;color: #fff;"
    >
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
          fill="currentColor"
        />
      </svg>
    </div>
  </div>
</header>

<section class="py-7 bg-gray-100 position-relative">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 mx-auto text-center" />
    </div>
    <div class="row mt-5">
      <div class="col-lg-10 mx-auto">
        <div class="card">
          <div class="row">
            <div class="col-lg-5 d-flex">
              <div class="bg-gradient-primary my-lg-3 ms-lg-3 border-radius-md">
                <img
                  src="../static/img/shapes/waves-white.svg"
                  alt="pattern-lines"
                  class="position-absolute start-0 top-0 h-100 opacity-6"
                />
                <div class="card-body p-5 position-relative">
                  <h3 class="text-white">Información de contacto</h3>
                  <p class="text-white opacity-8 mb-4">
                    Complete el formulario y nuestro equipo se comunicará con
                    usted dentro de las 24 horas.
                  </p>
                  <div class="d-flex p-2 text-white">
                    <div>
                      <i class="fas fa-phone text-sm" aria-hidden="true" />
                    </div>
                    <div class="ps-3">
                      <span class="text-sm opacity-8">+506 2451-4747</span>
                    </div>
                  </div>
                  <div class="d-flex p-2 text-white">
                    <div>
                      <i class="fas fa-envelope text-sm" aria-hidden="true" />
                    </div>
                    <div class="ps-3">
                      <span class="text-sm opacity-8"
                        >mmoritarty@liftmontacargas.com</span
                      >
                    </div>
                  </div>
                  <div class="d-flex p-2 text-white">
                    <div>
                      <i
                        class="fas fa-map-marker-alt text-sm"
                        aria-hidden="true"
                      />
                    </div>
                    <div class="ps-3">
                      <span class="text-sm opacity-8"
                        >300m oeste del peaje de Naranjo,<br />Alajuela, Costa
                        Rica</span
                      >
                    </div>
                  </div>
                  <div class="mt-4">
                    <button
                      type="button"
                      class="btn btn-icon-only btn-link text-white btn-lg mb-0"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      data-original-title="Log in with Facebook"
                      data-bs-original-title=""
                      title=""
                    >
                      <i class="fab fa-facebook" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      class="btn btn-icon-only btn-link text-white btn-lg mb-0"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      data-original-title="Log in with Instagram"
                      data-bs-original-title=""
                      title=""
                    >
                      <i class="fab fa-instagram" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-7">
              <form role="form" id="contact-form">
                <div class="card-body position-relative p-3 ps-0">
                  <div class="row mt-4">
                    <div class="col-md-6">
                      <label>Nombre completo</label>
                      <div class="input-group mb-4">
                        <input
                          type="text"
                          name="name"
                          class="form-control"
                          aria-label="Nombre completo..."
                          placeholder="Nombre completo"
                          required=""
                          bind:value={name}
                        />
                      </div>
                    </div>
                    <div class="col-md-6 ps-2">
                      <label>Número de teléfono</label>
                      <div class="input-group mb-4">
                        <input
                          type="tel"
                          name="phone"
                          class="form-control"
                          aria-label="Número de teléfono"
                          placeholder="Número de teléfono"
                          required=""
                          bind:value={phone}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label>Correo electrónico</label>
                    <div class="input-group mb-4">
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        placeholder="Correo electrónico"
                        required=""
                        bind:value={email}
                      />
                    </div>
                  </div>
                  <div class="form-group mt-4">
                    <label>Detalle de la cotización</label>
                    <textarea
                      name="message"
                      class="form-control"
                      id="message"
                      rows="6"
                      placeholder="Detalle"
                      required=""
                      bind:value={details}
                    />
                  </div>
                  <div class="text-right">
                    <button
                      disabled={sending}
                      type="button"
                      on:click={() => {RQF.submit()}}
                      class="btn btn-danger pull-right contact-submit-btn"
                    >
                      <span>
                        {#if sending}
                          <img
                            src="../static/img/btn-loading.svg"
                            width="20"
                            height="20"
                            alt="Loading..."
                          />
                        {:else}
                          Enviar mensaje
                        {/if}
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
