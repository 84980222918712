import { MainTarget, VIEWS } from "./store";
import Swal from "sweetalert2/dist/sweetalert2.all.js";
import 'sweetalert2/dist/sweetalert2.min.css';


 export class ChangeComponentEvent {
  constructor(component, params) {
    this.event = new CustomEvent("changeComponent", {
      detail: { component: component, params: params },
      bubbles: true,
    });
  }

  dispatch(target = MainTarget) {
    document.querySelector(target).dispatchEvent(this.event);
  }
}

export function navigateTo(view) {
  let destiny = view ? VIEWS[view] : VIEWS["404"];
  let evt = new ChangeComponentEvent(destiny);
  evt.dispatch();
}

/*
Usage: const processChange = debounce(() => saveInput());
*/
export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export var Cookie = {
  set: (name, value, days) => {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  },

  get: (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  },
};


export const Alert = {

  showError: (errorMessage, errorDescription) =>
  Swal.fire({
    timer: 2500,
    title: errorMessage,
    html: errorDescription,
    icon: "error",
  }),

  showSuccess: (successMessage, successDescription) =>
  Swal.fire({
    timer: 2500,
    title: successMessage,
    html: successDescription,
    icon: "success",
  }),

}

export const fakePromise = (time = 2000) =>
  new Promise((resolve) => setTimeout(resolve, 2000));
