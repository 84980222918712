// Api.js
import axios from "axios";
import Token from './token.js';

const axiosAPI = axios.create({
  withCredentials: false,
  baseURL: 'https://a4ekgczbc1.execute-api.us-east-1.amazonaws.com/production/'
});

let headers = {};

axiosAPI.interceptors.request.use(
  async (config) => {
    
    // let token = await Token.post("/get/", {'username':TOKEN_USR, "password": TOKEN_PWD});
    headers['Content-Type'] = 'application/json';
    
    config.headers = headers;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  });

// axiosAPI.interceptors.response.use(
//   (response) => {
//     return res;
//   },
//   async (error) => {
//     if (error.response) {
//       if (error.response.status === 401) {
//         // Do something, call refreshToken() request for example;
//         // return a request
//         return axios_instance(config);
//       }

//       if (error.response.status === ANOTHER_STATUS_CODE) {
//         // Do something 
//         return Promise.reject(error.response.data);
//       }
//     }

//     return Promise.reject(error);
//   }
// );

// implement a method to execute all the request from here.


const apiRequest = (method, url, request) => {
  //using the axios instance to perform the request that received from each http method

  var config = {
    method,
    url,
    headers,
  }
  if (method === 'get') {
    config['params'] = request;
  } else {
    config['data'] = request;
  }

  return axiosAPI(config)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

// function to execute the http get request
const get = (url, request) => apiRequest("get", url, request);

// function to execute the http delete request
const deleteRequest = (url, request) => apiRequest("delete", url, request);

// function to execute the http post request
const post = (url, request) => apiRequest("post", url, request);

// function to execute the http put request
const put = (url, request) => apiRequest("put", url, request);

// function to execute the http path request
const patch = (url, request) => apiRequest("patch", url, request);

const fakeGet = () => {
  return axios.get("https://reqres.in/api/users?page=2");
};

const fakePost = () => {
  return axios.post("https://reqres.in/api/users", {"name": "morpheus","job": "leader"});
};

// expose your method to other services or actions
const API = {
  fakeGet,
  fakePost,
  headers,
  get,
  delete: deleteRequest,
  post,
  put,
  patch,
};
export default API;
