<script>
  import { navigateTo } from "../util";


  let year = new Date().getFullYear();
  
</script>

<!-- -------- START FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT ------- -->
<footer class="footer py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mb-4 mx-auto text-center">
        <a
          href="#"
          on:click={() => { navigateTo('home') }}
          class="text-secondary me-xl-5 me-3 mb-sm-0 mb-2"
        >
          Inicio
        </a>
        <a
          href="#"
          on:click={() => { navigateTo('conditions') }}
          class="text-secondary me-xl-5 me-3 mb-sm-0 mb-2"
        >
          Aviso legal
        </a>
        <a
          href="#"
          on:click={() => { navigateTo('conditions') }}
          class="text-secondary me-xl-5 me-3 mb-sm-0 mb-2"
        >
          Política de privacidad
        </a>
        <a
          href="#"
          on:click={() => { navigateTo('conditions') }}
          class="text-secondary me-xl-5 me-3 mb-sm-0 mb-2"
        >
          Política de cookies
        </a>
        <a
          href="#"
          on:click={() => { navigateTo('about') }}
          class="text-secondary me-xl-5 me-3 mb-sm-0 mb-2"
        >
          Nosotros
        </a>
        <a
          href="#"
          on:click={() => { navigateTo('contact') }}
          class="text-secondary me-xl-5 me-3 mb-sm-0 mb-2"
        >
          Contacto
        </a>
      </div>
      <div class="col-lg-8 mx-auto text-center mb-4 mt-2">
        <a
          href="javascript:;"
          target="_blank"
          class="text-secondary me-xl-4 me-4"
        >
          <span class="text-lg fab fa-facebook" />
        </a>
        <a
          href="javascript:;"
          target="_blank"
          class="text-secondary me-xl-4 me-4"
        >
          <span class="text-lg fab fa-instagram" />
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-8 mx-auto text-center mt-1">
        <p class="mb-0 text-secondary">
          Copyright © {year} Forklift Equipment S.A.
        </p>
      </div>
    </div>
  </div>
</footer>
<!-- -------- END FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT ------- -->
