<script>
  import { postRequest } from "../../services/request";
  import { fakePromise, Alert } from "../../util";
  import _ from "lodash";

  export let name, phone, email, details, form;
  
  export let sending = false;

  function reset() {
    name = "";
    phone = "";
    email = "";
    details = "";
  }

  function beforeSending() {
    sending = true;
    jQuery(form)
      .find("input, textarea")
      .each((idx, element) => {
        jQuery(element).prop("disabled", true);
      });
  }

  function doneSending() {
    sending = false;
    jQuery(form)
      .find("input, textarea")
      .each((idx, element) => {
        jQuery(element).prop("disabled", false);
      });
  }

  function validate() {
    let flag = false;
    let data = [name, phone, email, details];
    data.forEach((field) => {
      if (_.isEmpty(field)) {
        // errors.push(field);
        flag = true;
      }
    });
    return !flag;
  }

  export async function submit(modalHideCallback=null) {
    beforeSending();

    if (!validate()) {
      Alert.showError("Debes llenar todos los campos");
      reset();

      doneSending();
      return;
    }

    let data = {
      name: name,
      phone: phone,
      email: email,
      details: details,
      status: "OPEN",
      company: "lift"
    };

    let res = await postRequest(data);

    if (res?.status && res.status > 399 && res.status < 600) {
      Alert.showError("Ocurrio un error", "Intenta mas tarde");
    } else {
      Alert.showSuccess("Listo!", "Pronto nos pondremos en contacto");
    }

    reset();

    doneSending();
    
  }
</script>

