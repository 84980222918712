<script>
  import "bootstrap/dist/css/bootstrap.css";
  import "bootstrap/dist/js/bootstrap.bundle.js";
  import { Router, Route } from "svelte-routing";
  import Navbar from "./components/Navbar.svelte";
  import Sale from "./components/Sale.svelte";
  import Footer from "./components/Footer.svelte";
  import Home from "./views/Home.svelte";
  import Contact from "./views/Contact.svelte";
  import About from "./views/About.svelte";
  import LegalTerms from "./views/LegalTerms.svelte";
  export let url = "";
  import { Swal } from "sweetalert2/dist/sweetalert2.all";


  let currentComponent = Home;
    let componentParams = {};
  
    function handleComponentChange(e){
      currentComponent = e.detail.component;
      componentParams = e.detail.params;
    }


</script>

<svelte:window on:changeComponent={handleComponentChange}/>


<Navbar />

<!--<Router {url}>
  <Route path="/sale" component={Sale} />
  <Route path="/conditions" component={LegalTerms} />
  <Route path="/contact" component={Contact} />
  <Route path="/about" component={About} />
  <Route path="/" component={Home} />
</Router>-->
<main>
  <svelte:component this={currentComponent} {...componentParams} />
</main>


<Footer />